import { SafeAny } from "./common";

export type ActionMap<M extends { [index: string]: SafeAny }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

export interface InitialState {
  title: string;
  meta: string;
}

export enum EAction {
  CHANGE_TITLE_AND_TAG = "CHANGE_TITLE_AND_TAG",
}

export interface TitleAndTagPayload {
  title: string;
  meta: string;
}

export interface ChangeTitleAndTag {
  type: EAction.CHANGE_TITLE_AND_TAG;
  payload: TitleAndTagPayload;
}
