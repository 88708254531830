import { useMediaQuery, useTheme } from "@material-ui/core";

export const useBreakpoints = () => {
  const theme = useTheme();
  const isLessThanXs = useMediaQuery(theme.breakpoints.down("xs"));
  const isLessThanSm = useMediaQuery(theme.breakpoints.down("sm"));
  const isLessThanMd = useMediaQuery(theme.breakpoints.down("md"));
  const isLessThanLg = useMediaQuery(theme.breakpoints.down("lg"));
  const isLessThanXl = useMediaQuery(theme.breakpoints.down("xl"));
  const isMoreThanXs = useMediaQuery(theme.breakpoints.up("xs"));
  const isMoreThanSm = useMediaQuery(theme.breakpoints.up("sm"));
  const isMoreThanMd = useMediaQuery(theme.breakpoints.up("md"));
  const isMoreThanLg = useMediaQuery(theme.breakpoints.up("lg"));
  const isMoreThanXl = useMediaQuery(theme.breakpoints.up("xl"));
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));
  const isSm = useMediaQuery(theme.breakpoints.only("sm"));
  const isMd = useMediaQuery(theme.breakpoints.only("md"));
  const isLg = useMediaQuery(theme.breakpoints.only("lg"));
  const isXl = useMediaQuery(theme.breakpoints.only("xl"));

  return {
    isMoreThanXs,
    isLessThanXs,
    isXs,
    isMoreThanSm,
    isLessThanSm,
    isSm,
    isMoreThanMd,
    isLessThanMd,
    isMd,
    isMoreThanLg,
    isLessThanLg,
    isLg,
    isMoreThanXl,
    isLessThanXl,
    isXl,
  };
};
