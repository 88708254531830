import React, { ReactElement } from "react";
import { Routes as RoutesType, Route as RouteType } from "@models/routes";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { flattenRoutes } from "@utils/routes";
import { MainLayout } from "@layouts/MainLayout";
import { HomepageRoutes } from "./Homepage";
import { ProductRoutes } from "./Product";
import { GrantRoutes } from "./Grant";
import { CompanyRoutes } from "./Company";
import { HelpRoutes } from "./Help";
import { TermsAndConditionsRouters } from "./TermsAndConditions";
import { EstimateRoutes } from "./Estimate";
import { LocationRoutes } from "./Location";
import { ViewProductRoutes } from "./ViewProduct";
import { PageNotFound } from "@pages/404Page";
import { BlogRouters } from "./Blog";

const routes: RoutesType = flattenRoutes([
  ...HomepageRoutes,
  ...ProductRoutes,
  ...GrantRoutes,
  ...CompanyRoutes,
  ...HelpRoutes,
  ...TermsAndConditionsRouters,
  ...EstimateRoutes,
  ...LocationRoutes,
  ...ViewProductRoutes,
  ...BlogRouters,
]);

const AppRoute = ({ component: Component }: RouteType): ReactElement => {
  return (
    <React.Suspense fallback={null}>
      <MainLayout>
        <Component />
      </MainLayout>
    </React.Suspense>
  );
};

export const AppRoutes = (): React.ReactElement => (
  <BrowserRouter>
    <Routes>
      <Route path="/company" element={<Navigate to="/company/about-us" replace />} />
      {routes.map((r) => (
        <Route path={r.path} key={r.path} element={<AppRoute component={r.component} path={r.path} />} />
      ))}
      <Route path="/404" element={<PageNotFound />} />
      <Route path="*" element={<Navigate to="/404" replace />} />
    </Routes>
  </BrowserRouter>
);
