import { Routes } from "@models/routes";
import { lazy } from "react";

const EstimatePage = lazy(() => import("@pages/estimate/index").then((p) => ({ default: p.EstimatePage })));

export const EstimateRoutes: Routes = [
  {
    path: "/estimate",
    component: EstimatePage,
  },
];
