import { Accordion, AccordionDetails, AccordionSummary, Box, makeStyles, Typography } from "@material-ui/core";
import { ArrowForwardIosOutlined, ChevronRightOutlined, ExpandLess } from "@material-ui/icons";
import { Menu, Menus } from "@models";
import { colors } from "@theme";
import React from "react";
import { useNavigate } from "react-router-dom";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

export const MobileMenuComponent = ({
  data,
  setOpenDrawer,
}: {
  data: Menus;
  setOpenDrawer: (drawer: boolean) => void;
}) => {
  const [openDrawerChildren, setOpenDrawerChildren] = React.useState<boolean>(false);

  const classes = useStyles();
  const navigate = useNavigate();
  const handleSubMenuClick = (subMenu: Menu) => {
    if (subMenu.title === "Finance") {
      setOpenDrawerChildren(!openDrawerChildren);
    } else {
      navigate(subMenu.path);
      setOpenDrawer(false);
      setOpenDrawerChildren(true);
    }
  };
  const handleClickTitle = () => {
    if (!data.isLink) {
      return;
    }
    navigate(data.path);
    setOpenDrawer(false);
  };
  const handleClickAccordion = () => {
    if (!data.subMenu) {
      navigate(data.path);
      setOpenDrawer(false);
    }
  };

  const handleSubMenuChildrenClick = (subMenu: Menu) => {
    navigate(subMenu.path);
    setOpenDrawer(false);
    setOpenDrawerChildren(false);
  };

  return (
    <Box width="100%">
      <Accordion className={classes.customAccordion}>
        <AccordionSummary
          aria-controls={data.title}
          id={data.title}
          expandIcon={
            data.subMenu ? (
              <ExpandMoreIcon color="primary" />
            ) : (
              <ChevronRightOutlined style={{ fontSize: 24 }} color="primary" />
            )
          }
          onClick={handleClickAccordion}
        >
          <Typography onClick={handleClickTitle} className="whiteText">
            {data.title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box display="flex" flexDirection="column" width={"100%"}>
            {data.subMenu &&
              data.subMenu.map((subMenu, index) => (
                <React.Fragment key={index}>
                  <Box my={1} display="flex" color={colors.white} onClick={() => handleSubMenuClick(subMenu)}>
                    <Typography className="pinkColor font-extralight">{subMenu.title}</Typography>
                    <Box ml="auto">
                      {subMenu.title === "Finance" ? (
                        <>{openDrawerChildren ? <ExpandLess color="primary" /> : <ExpandMoreIcon color="primary" />}</>
                      ) : (
                        <ArrowForwardIosOutlined style={{ fontSize: 16 }} color="primary" />
                      )}
                    </Box>
                  </Box>

                  {openDrawerChildren && (subMenu?.subMenu || [])?.length > 0 && (
                    <Box paddingLeft={2}>
                      {subMenu?.subMenu &&
                        subMenu.subMenu.map((subChildren, indexChildren) => (
                          <Box
                            key={indexChildren}
                            my={1.5}
                            display="flex"
                            color={colors.white}
                            onClick={() => handleSubMenuChildrenClick(subChildren)}
                          >
                            <Typography className="pinkColor font-extralight">{subChildren.title}</Typography>
                            <Box ml="auto">
                              <ArrowForwardIosOutlined style={{ fontSize: 16 }} color="primary" />
                            </Box>
                          </Box>
                        ))}
                    </Box>
                  )}
                </React.Fragment>
              ))}
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

const useStyles = makeStyles({
  customAccordion: {
    background: "unset",
    boxShadow: "unset",
    "& .MuiAccordionSummary-root": {
      background: colors.secondary.dark,
    },
  },
});
