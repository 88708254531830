import { Box, Typography } from "@material-ui/core";
import { ArrowForwardIosOutlined, ExpandMoreOutlined } from "@material-ui/icons";
import { Menus } from "@models";
import { boxShadow, colors } from "@theme";
import { useBreakpoints } from "@utils/breakpoints";
import React from "react";
import { useNavigate } from "react-router-dom";

export const MenuComponent = ({ data, isBlack }: { data: Menus; isBlack: boolean }) => {
  const [isOpenSubMenu, setIsOpenSubMenu] = React.useState<boolean>(false);
  const [isOpenSubMenuChildren, setIsOpenSubMenuChildren] = React.useState<boolean>(true);
  const { isLessThanMd } = useBreakpoints();
  const navigate = useNavigate();
  const onClickTitle = () => {
    if (!data.isLink) {
      return;
    }
    setIsOpenSubMenu(false);
    navigate(data.path);
  };

  return (
    <Box
      className="cursor-pointer"
      onMouseEnter={() => {
        setIsOpenSubMenu(true);
        setIsOpenSubMenuChildren(false);
      }}
      onMouseLeave={() => {
        setIsOpenSubMenu(false);
        setIsOpenSubMenuChildren(false);
      }}
      position="relative"
      py={2}
    >
      <Box
        bgcolor={isOpenSubMenu ? (isBlack ? colors.white : "#4A3865") : "unset"}
        py={1}
        px={1}
        borderRadius={10}
        display="flex"
        alignItems="center"
        minWidth={data.width || 0}
        justifyContent={"space-between"}
        onClick={onClickTitle}
        boxShadow={isBlack && isOpenSubMenu ? boxShadow : "none"}
      >
        <Box position="relative">
          <Typography
            variant={isLessThanMd ? "overline" : "body1"}
            className={isBlack ? "" : "text-white"}
            color={isBlack ? "secondary" : "initial"}
          >
            {data.title}
          </Typography>
        </Box>
        {data.subMenu && (
          <Box
            ml={0.5}
            color={isBlack ? colors.primary.main : colors.white}
            className={isOpenSubMenu ? "opacity-100" : "opacity-0"}
          >
            <ExpandMoreOutlined />
          </Box>
        )}
      </Box>
      {isOpenSubMenu && data.subMenu && (
        <Box
          position="absolute"
          top="100%"
          display="flex"
          flexDirection="column"
          bgcolor={isBlack ? colors.white : "#4A3865"}
          minWidth={195}
          borderRadius={10}
          boxShadow={isBlack ? boxShadow : "none"}
        >
          {data?.subMenu?.map((subMenu, index) => (
            <div key={index} className="relative">
              <Box
                display="flex"
                px={2}
                py={1}
                onClick={() => {
                  if (subMenu.title === "Finance") return;
                  navigate(subMenu.path);
                  setIsOpenSubMenu(false);
                }}
                onMouseEnter={() => {
                  const isSubMenuChildren = !!(subMenu?.subMenu && subMenu?.subMenu?.length > 0);
                  setIsOpenSubMenuChildren(isSubMenuChildren);
                }}
              >
                <Box mr={1}>
                  <Typography
                    variant="caption"
                    className={isBlack ? "" : "text-white"}
                    color={isBlack ? "secondary" : "initial"}
                  >
                    {subMenu.title}
                  </Typography>
                </Box>

                <Box ml="auto">
                  <ArrowForwardIosOutlined color="primary" style={{ fontSize: 16 }} />
                </Box>
              </Box>
              {index < (data.subMenu || []).length - 1 && <Box width="100%" height={"1px"} bgcolor={"#342052"} />}

              {isOpenSubMenuChildren && (
                <Box position="absolute" top={0} right={-200} pl={4}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    bgcolor={isBlack ? colors.white : "#4A3865"}
                    minWidth={185}
                    borderRadius={10}
                    boxShadow={isBlack ? boxShadow : "none"}
                    zIndex={10}
                  >
                    {subMenu?.subMenu?.map((subMenu, index) => (
                      <React.Fragment key={index}>
                        <Box
                          display="flex"
                          px={2}
                          py={1}
                          onClick={() => {
                            navigate(subMenu.path);
                            setIsOpenSubMenu(false);
                            setIsOpenSubMenuChildren(false);
                          }}
                        >
                          <Box mr={1}>
                            <Typography
                              variant="caption"
                              className={isBlack ? "" : "text-white"}
                              color={isBlack ? "secondary" : "initial"}
                            >
                              {subMenu.title}
                            </Typography>
                          </Box>

                          <Box ml="auto">
                            <ArrowForwardIosOutlined color="primary" style={{ fontSize: 16 }} />
                          </Box>
                        </Box>
                        {index < (subMenu.subMenu || []).length - 1 && (
                          <Box width="100%" height={"1px"} bgcolor={"#342052"} />
                        )}
                      </React.Fragment>
                    ))}
                  </Box>
                </Box>
              )}
            </div>
          ))}
        </Box>
      )}
    </Box>
  );
};
