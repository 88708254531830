import { Routes } from "@models/routes";
import { lazy } from "react";

const FAQPage = lazy(() => import("@pages/help/FAQ").then((p) => ({ default: p.FAQ })));

const FinancePage = lazy(() => import("@pages/help/Finance").then((p) => ({ default: p.Finance })));
const SolarDomesticFinancePage = lazy(() =>
  import("@pages/help/SolarDomesticFinance").then((p) => ({
    default: p.SolarDomesticFinance,
  }))
);

const SolarCommercialFinancePage = lazy(() =>
  import("@pages/help/SolarCommercialFinance").then((p) => ({
    default: p.SolarCommercialFinance,
  }))
);

const SolarAgricultureFinance = lazy(() =>
  import("@pages/help/SolarAgricultureFinance").then((p) => ({
    default: p.SolarAgricultureFinance,
  }))
);

const LocationPage = lazy(() => import("@pages/help/Location").then((p) => ({ default: p.Location })));

export const HelpRoutes: Routes = [
  {
    path: "/faq",
    component: FAQPage,
  },
  {
    path: "/boiler-finance",
    component: FinancePage,
  },
  {
    path: "/solar-domestic-finance",
    component: SolarDomesticFinancePage,
  },
  {
    path: "/solar-agriculture-finance",
    component: SolarAgricultureFinance,
  },
  {
    path: "/solar-commercial-finance",
    component: SolarCommercialFinancePage,
  },
  {
    path: "/locations",
    component: LocationPage,
  },
];
