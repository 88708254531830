import { Routes } from "@models/routes";
import { lazy } from "react";

const Homepage = lazy(() => import("@pages/homepage/index").then((p) => ({ default: p.HomePage })));

export const HomepageRoutes: Routes = [
  {
    path: "/",
    component: Homepage,
  },
];
