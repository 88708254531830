import { Routes } from "@models/routes";
import { lazy } from "react";

const Product = lazy(() => import("@pages/view-product/index").then((p) => ({ default: p.ViewProduct })));

const BoilerProduct = lazy(() => import("@pages/product/Boiler").then((p) => ({ default: p.Boiler })));

const BoilerLandingProduct = lazy(() => import("@pages/product/Boiler-Landing").then((p) => ({ default: p.Boiler })));

const SolarProduct = lazy(() => import("@pages/product/Solar").then((p) => ({ default: p.Solar })));

const SolarAgriculture = lazy(() =>
  import("@pages/product/SolarAgriculture").then((p) => ({
    default: p.SolarAgriculture,
  }))
);

const SolarLandingAgriculture = lazy(() =>
  import("@pages/product/SolarLandingAgriculture").then((p) => ({
    default: p.SolarAgriculture,
  }))
);

const SolarCommercial = lazy(() =>
  import("@pages/product/SolarCommercial").then((p) => ({
    default: p.SolarCommercial,
  }))
);

const SolarSchools = lazy(() =>
  import("@pages/product/SolarSchools").then((p) => ({
    default: p.SolarSchools,
  }))
);

const ProductList = lazy(() =>
  import("@pages/product/product-list/index").then((p) => ({
    default: p.ProductList,
  }))
);

const ProductDetail = lazy(() =>
  import("@pages/product/product-details/index").then((p) => ({
    default: p.ProductDetail,
  }))
);

export const ProductRoutes: Routes = [
  {
    path: "boiler-replacement",
    component: BoilerProduct,
  },
  {
    path: "boiler-replacement-ppc",
    component: BoilerLandingProduct,
  },
  {
    path: "solar-panels-ireland",
    component: SolarProduct,
  },
  {
    path: "agricultural-solar-panels",
    component: SolarAgriculture,
  },
  {
    path: "agricultural-solar-panels-landing",
    component: SolarLandingAgriculture,
  },
  {
    path: "commercial-solar-panels",
    component: SolarCommercial,
  },
  {
    path: "schools-solar-panels",
    component: SolarSchools,
  },
  {
    path: "/products",
    component: Product,
    children: [
      {
        path: ":type",
        component: ProductList,
      },
      { path: ":type/:name", component: ProductDetail },
    ],
  },
  // {
  //   path: "/services",
  //   component: Product,
  //   children: [
  //     {
  //       path: ":type",
  //       component: Product,
  //     },
  //     { path: ":type/products", component: ProductList },
  //     { path: ":type/products/:name", component: ProductDetail },
  //   ],
  // },
];
