import { Routes } from "@models/routes";
import { lazy } from "react";

const BoilerGrantPage = lazy(() => import("@pages/grant/BoilerGrant").then((p) => ({ default: p.BoilerGrant })));

const SolarGrantPage = lazy(() => import("@pages/grant/SolarGrant").then((p) => ({ default: p.SolarGrant })));

const SolarAgricultureGrantPage = lazy(() =>
  import("@pages/grant/SolarAgricultureGrant").then((p) => ({
    default: p.SolarAgricultureGrant,
  }))
);

const SolarNonDomesticGrantPage = lazy(() =>
  import("@pages/grant/SolarNonDomesticGrant").then((p) => ({
    default: p.SolarNonDomesticGrant,
  }))
);

export const GrantRoutes: Routes = [
  {
    path: "/boiler-grants",
    component: BoilerGrantPage,
  },
  {
    path: "/solar-panel-grant-ireland",
    component: SolarGrantPage,
  },
  {
    path: "/solar-agriculture-grants",
    component: SolarAgricultureGrantPage,
  },
  {
    path: "/solar-commercial-grants",
    component: SolarNonDomesticGrantPage,
  },
];
