import { useAppContext } from "@contexts/Context";
import React from "react";
import { Helmet } from "react-helmet-async";

export const HelmetComponent = () => {
  const {
    state: { title, meta: description },
  } = useAppContext();

  if (!title || !description) {
    return null;
  }

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      {/* End standard metadata tags */}
      {/* Facebook tags */}
      <meta property="og:type" content={"website"} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      {/* End Facebook tags */}
      {/* Twitter tags */}

      <meta name="twitter:card" content={"website"} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      {/* End Twitter tags */}
    </Helmet>
  );
};
