import React from "react";
import { Box, Grid, Toolbar, Typography } from "@material-ui/core";
import { colors } from "@theme";
import { FacebookIcon, LinkedinIcon, TwitterIcon, YoutubeIcon } from "./icon";
import { useBreakpoints } from "@utils/breakpoints";
import { useNavigate } from "react-router-dom";
import { googleReviewLink, trustpilotReviewLink, socialLink } from "@constants";

export const MainFooter = () => {
  const { isLessThanXs, isLessThanMd } = useBreakpoints();
  const navigate = useNavigate();
  const handleSocialClick = (link: string) => {
    if (!link) {
      return;
    }
    window.open(link, "_blank")?.focus();
  };

  if (isLessThanMd) {
    return (
      <Box
        className={"mt-auto py-4 w-full"}
        // height={124}
        bgcolor={colors.secondary.main}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          width="80%"
          mx="auto"
          alignItems="center"
          height="100%"
          maxWidth={1290}
          flexWrap="wrap"
        >
          <Box height={36} display="flex" alignItems="center" onClick={() => navigate("/")} className="cursor-pointer">
            <img loading="lazy" alt="" src={"/assets/images/Swyft_logo.svg"} className="h-full mr-4" />
          </Box>
          <Box display="flex">
            <Box
              mr={0.5}
              className="cursor-pointer"
              color={colors.pink}
              onClick={() => handleSocialClick(socialLink.facebook)}
            >
              <FacebookIcon />
            </Box>
            <Box
              mx={0.5}
              className="cursor-pointer"
              color={colors.pink}
              onClick={() => handleSocialClick(socialLink.twitter)}
            >
              <TwitterIcon />
            </Box>
            <Box
              mx={0.5}
              className="cursor-pointer"
              color={colors.pink}
              onClick={() => handleSocialClick(socialLink.linkedIn)}
            >
              <LinkedinIcon />
            </Box>
            <Box
              mx={0.5}
              className="cursor-pointer"
              color={colors.pink}
              onClick={() => handleSocialClick(socialLink.youtube)}
            >
              <YoutubeIcon />
            </Box>
          </Box>
        </Box>
        <Box width="80%" mx="auto" mt={5}>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <Box mb={1}>
                <Typography variant={isLessThanXs ? "body2" : "body1"} className="violetColor uppercase">
                  Get in Touch
                </Typography>
              </Box>
              <Box mb={1}>
                <Typography variant={isLessThanXs ? "body2" : "body1"} className="whiteText font-light">
                  Cork: (021) 701 1238
                </Typography>
              </Box>
              <Box mb={1}>
                <Typography variant={isLessThanXs ? "body2" : "body1"} className="whiteText font-light">
                  Dublin: (01) 504 2111
                </Typography>
              </Box>
              <Box>
                <Typography variant={isLessThanXs ? "body2" : "body1"} className="whiteText font-light">
                  E: hi@swyftenergy.ie
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box mb={1} mt={isLessThanXs ? 4 : 0}>
                <Typography variant={isLessThanXs ? "body2" : "body1"} className="violetColor uppercase">
                  Headquarters
                </Typography>
              </Box>
              <Box mb={1}>
                <Typography variant={isLessThanXs ? "body2" : "body1"} className="whiteText font-light">
                  CORK
                </Typography>
              </Box>
              <Box mb={1}>
                <Typography variant={isLessThanXs ? "body2" : "body1"} className="whiteText font-light">
                  The Rubicon Centre, Bishopstown, Cork
                </Typography>
              </Box>
              <Box>
                <Typography variant={isLessThanXs ? "body2" : "body1"} className="whiteText font-light">
                  T12 Y275
                </Typography>
              </Box>
              <Box mb={1} mt={4}>
                <Typography variant={isLessThanXs ? "body2" : "body1"} className="whiteText font-light">
                  DUBLIN
                </Typography>
              </Box>
              <Box mb={1}>
                <Typography variant={isLessThanXs ? "body2" : "body1"} className="whiteText font-light">
                  Unit 17 North West Centre, North West Business Park, Dublin 15, D15DH24
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
            <Box mt={isLessThanXs ? 4 : 1} onClick={() => navigate("/terms-and-conditions-domestic-agricultural-solar")}>
                <Typography variant="body2" className="font-light violetColor cursor-pointer">
                  Terms & Conditions (Domestic & Agricultural Solar)
                </Typography>
              </Box>
              <Box mt={isLessThanXs ? 4 : 1} onClick={() => navigate("/terms-and-conditions-commercial-solar")}>
                <Typography variant="body2" className="font-light violetColor cursor-pointer">
                  Terms & Conditions (Commercial Solar)
                </Typography>
              </Box>
              <Box mt={isLessThanXs ? 4 : 1} onClick={() => navigate("/terms-and-conditions-boilers")}>
                <Typography variant="body2" className="font-light violetColor cursor-pointer">
                  Terms & Conditions (General)
                </Typography>
              </Box>
              <Box mt={1} onClick={() => navigate("/terms-and-conditions-servicing")}>
                <Typography variant="body2" className="font-light violetColor cursor-pointer">
                  Terms & Conditions (Servicing)
                </Typography>
              </Box>
              <Box mt={1} onClick={() => navigate("/privacy-policy")}>
                <Typography variant="body2" className="font-light violetColor cursor-pointer">
                  Privacy Policy
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    );
  }

  return (
    <Box bgcolor={colors.secondary.light} width="100%" mt="auto">
      <Toolbar>
        <Box py={4} width="100%" className="box-container">
          <Grid container>
            <Grid item xs={3}>
              <Box onClick={() => navigate("/")} className="cursor-pointer">
                <img loading="lazy" alt="" src={"/assets/images/Swyft_logo.svg"} />
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box mb={1}>
                <Typography variant="body1" className="violetColor uppercase">
                  Get in Touch
                </Typography>
              </Box>
              <Box mb={1}>
                <Typography variant="h5" className="whiteText font-light">
                  Cork: (021) 701 1238
                </Typography>
              </Box>
              <Box mb={1}>
                <Typography variant="h5" className="whiteText font-light">
                  Dublin: (01) 504 2111
                </Typography>
              </Box>
              <Box>
                <Typography variant="h5" className="whiteText font-light">
                  E: hi@swyftenergy.ie
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box mb={1}>
                <Typography variant="body1" className="violetColor uppercase">
                  Headquarters
                </Typography>
              </Box>
              <Box mb={1}>
                <Typography variant="body1" className="whiteText font-light">
                  CORK
                </Typography>
              </Box>
              <Box mb={1}>
                <Typography variant="body1" className="whiteText font-light">
                  The Rubicon Centre, Bishopstown, Cork
                </Typography>
              </Box>
              <Box>
                <Typography variant="body1" className="whiteText font-light">
                  T12 Y275
                </Typography>
              </Box>
              <Box mb={1} mt={4}>
                <Typography variant="body1" className="whiteText font-light">
                  DUBLIN
                </Typography>
              </Box>
              <Box mb={1}>
                <Typography variant="body1" className="whiteText font-light">
                  Unit 17 North West Centre, North West Business Park, Dublin 15, D15DH24
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box pl={4} pr={1}>
                <Box display="flex">
                  <Box
                    mr={0.5}
                    className="cursor-pointer"
                    color={colors.pink}
                    onClick={() => handleSocialClick(socialLink.facebook)}
                  >
                    <FacebookIcon />
                  </Box>
                  <Box
                    mx={0.5}
                    className="cursor-pointer"
                    color={colors.pink}
                    onClick={() => handleSocialClick(socialLink.twitter)}
                  >
                    <TwitterIcon />
                  </Box>
                  <Box
                    mx={0.5}
                    className="cursor-pointer"
                    color={colors.pink}
                    onClick={() => handleSocialClick(socialLink.linkedIn)}
                  >
                    <LinkedinIcon />
                  </Box>
                  <Box
                    mx={0.5}
                    className="cursor-pointer"
                    color={colors.pink}
                    onClick={() => handleSocialClick(socialLink.youtube)}
                  >
                    <YoutubeIcon />
                  </Box>
                </Box>
                <Box maxWidth={290} my={4}>
                  <img loading="lazy" src={"/assets/images/5star.svg"} className="mb-2" />
                  <Typography variant="h5" className="font-light whiteText">
                    Rated <span className="font-semibold">4.8/5 stars</span> over{" "}
                    <span className="font-semibold">1,500+ customer reviews</span>
                  </Typography>
                </Box>
                <Box className="cursor-pointer" onClick={() => handleSocialClick(googleReviewLink)} mt={-1}>
                  <Typography variant="h5" className="violetColor font-light">
                    Leave us a Google review
                  </Typography>
                </Box>
                <Box className="cursor-pointer" onClick={() => handleSocialClick(trustpilotReviewLink)}>
                <Typography variant="h5" className="violetColor font-light">
                    Leave us a TrustPilot review
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Box display="flex" justifyContent="center" mt={6}>
          <Box mx={3} onClick={() => navigate("/terms-and-conditions-domestic-agricultural-solar")}>
              <Typography variant="body2" className="font-light violetColor cursor-pointer">
                Terms & Conditions (Domestic & Agricultural Solar)
              </Typography>
            </Box>
            <Box mx={3} onClick={() => navigate("/terms-and-conditions-commercial-solar")}>
              <Typography variant="body2" className="font-light violetColor cursor-pointer">
                Terms & Conditions (Commercial Solar)
              </Typography>
            </Box>
            <Box mx={3} onClick={() => navigate("/terms-and-conditions-boilers")}>
              <Typography variant="body2" className="font-light violetColor cursor-pointer">
                Terms & Conditions (Boilers)
              </Typography>
            </Box>
            <Box mx={3} onClick={() => navigate("/terms-and-conditions-servicing")}>
              <Typography variant="body2" className="font-light violetColor cursor-pointer">
                Terms & Conditions (Servicing)
              </Typography>
            </Box>
            <Box mx={3} onClick={() => navigate("/privacy-policy")}>
              <Typography variant="body2" className="font-light violetColor cursor-pointer">
                Privacy Policy
              </Typography>
            </Box>
          </Box>
        </Box>
      </Toolbar>
    </Box>
  );
};
