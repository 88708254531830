import { Routes } from "@models/routes";
import { lazy } from "react";

const Domestic_Agricultural = lazy(() =>
  import("@pages/terms-conditions/Domestic-Agricultural-Solar").then((p) => ({
    default: p.Domestic_Agricultural ,
  }))
);

const Commercial = lazy(() =>
  import("@pages/terms-conditions/Commercial-Solar").then((p) => ({
    default: p.Commercial ,
  }))
);

const General = lazy(() =>
  import("@pages/terms-conditions/Boilers").then((p) => ({
    default: p.General,
  }))
);

const Servicing = lazy(() =>
  import("@pages/terms-conditions/Servicing").then((p) => ({
    default: p.Servicing,
  }))
);

const Navigation = lazy(() =>
  import("@pages/terms-conditions/Navigation").then((p) => ({
    default: p.Navigation,
  }))
);

const Privacy = lazy(() =>
  import("@pages/terms-conditions/Privacy").then((p) => ({
    default: p.Privacy,
  }))
);

export const TermsAndConditionsRouters: Routes = [
  
  {
    path: "/terms-and-conditions-domestic-agricultural-solar",
    component: Domestic_Agricultural,
  },
  {
    path: "/terms-and-conditions-commercial-solar",
    component: Commercial,
  },
  {
    path: "/terms-and-conditions-boilers",
    component: General,
  },
  {
    path: "/terms-and-conditions-servicing",
    component: Servicing,
  },
  {
    path: "/terms-and-conditions-navigation",
    component: Navigation,
  },
  {
    path: "/privacy-policy",
    component: Privacy,
  },
];
