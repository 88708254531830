import { createTheme, hexToRgb, ThemeOptions } from "@material-ui/core";

export function hexToRgbA(hex: string, alpha = 1) {
  const rgb = hexToRgb(hex);
  return rgb.replace(")", "," + alpha + ")");
}

export const backdropZIndex = 99999999;

export const boxShadowUp = "6px 0px 6px 2px rgba(0, 0, 0, 0.16)";

export const boxShadow =
  "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)";

const fontFamily = "Axiforma, sans-serif";

export const colors = {
  primary: {
    main: "#F7815C",
    light: "#FEEFEB",
    dark: "#AE593E",
  },
  secondary: {
    main: "#160037",
    light: "#160037",
    dark: "#12002d",
  },
  success: {
    main: "#57BE3F",
    light: "#cdecc5",
    dark: "#178D03",
  },
  error: {
    main: "#FF4040",
    light: "#ffd9d9",
    dark: "#C40018",
  },
  warning: {
    main: "#F9A822",
    light: "#feeed3",
    dark: "#C17900",
  },
  info: {
    main: "#1CA2F9",
    light: "#d2ecfe",
    dark: "#0074C6",
  },
  grey: {
    50: "#ecedff",
    100: "#EFEFEF",
    200: "#F8F8F8",
    300: "#97a1c3",
    400: "#818db1",
    500: "#6c79a1",
    600: "#F7F7F7",
    700: "#4B5462",
    800: "#3C4561",
    900: "#283149",
    1000: "#8d8d8d",
    DEFAULT: "#4B5462",
  },
  white: "#FFFFFF",
  violet: "#CEA3E2",
  pink: "#CDA3E3",
  rose: "#4A3865",
};

const breakpoints = {
  values: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
  },
};

export const MuiTheme: ThemeOptions = {
  breakpoints,
  palette: {
    primary: {
      main: colors.primary.main,
      light: colors.primary.light,
      dark: colors.primary.dark,
    },
    secondary: {
      main: colors.secondary.main,
      light: colors.secondary.light,
      dark: colors.secondary.dark,
    },
    success: {
      main: colors.success.main,
      light: colors.success.light,
      dark: colors.success.dark,
    },
    error: {
      main: colors.error.main,
      light: colors.error.light,
      dark: colors.error.dark,
    },
    warning: {
      main: colors.warning.main,
      light: colors.warning.light,
      dark: colors.warning.dark,
    },
    info: {
      main: colors.info.main,
      light: colors.info.light,
      dark: colors.info.dark,
    },
  },
  typography: {
    fontFamily: fontFamily,
    h1: {
      fontWeight: 300,
      letterSpacing: -1.5,
      lineHeight: "60px",
      fontSize: "49px",
    },
    h2: {
      fontWeight: 300,
      letterSpacing: -0.5,
      lineHeight: "58px",
      fontSize: "37px",
    },
    h3: {
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: "35px",
      fontSize: "30px",
    },
    h4: {
      fontWeight: 400,
      letterSpacing: 0.25,
      lineHeight: "36px",
      fontSize: "23px",
    },
    h5: {
      fontWeight: 400,
      letterSpacing: 0.25,
      lineHeight: "32px",
      fontSize: "19px",
    },
    h6: {
      fontWeight: 500,
      letterSpacing: 0.15,
      lineHeight: "28px",
      fontSize: "16px",
    },
    subtitle1: {
      fontWeight: 400,
      letterSpacing: 0.15,
      lineHeight: "24px",
      fontSize: "23px",
    },
    subtitle2: {
      fontWeight: 500,
      letterSpacing: 0,
      lineHeight: "35px",
      fontSize: "20px",
    },
    body1: {
      fontWeight: 400,
      letterSpacing: 0.5,
      lineHeight: 1.5,
      fontSize: "16px",
    },
    body2: {
      fontWeight: 400,
      letterSpacing: 0.25,
      lineHeight: "20px",
      fontSize: "14px",
    },
    caption: {
      fontWeight: 400,
      letterSpacing: 0.4,
      lineHeight: "16px",
      fontSize: "12px",
    },
    overline: {
      fontWeight: 400,
      letterSpacing: 0.5,
      lineHeight: "16px",
      fontSize: "10px",
      textTransform: "initial",
    },
    button: {
      fontWeight: 600,
      letterSpacing: 1.25,
      lineHeight: "16px",
      fontSize: "16px",
    },
  },
  overrides: {
    MuiLinearProgress: {
      root: {
        height: 5,
      },
      barColorPrimary: {
        backgroundColor: colors.primary.main,
      },
      colorPrimary: {
        backgroundColor: colors.white,
      },
    },
    MuiTypography: {
      root: {
        "&.semiBold": {
          fontWeight: 600,
        },
        "&.labelText": {
          color: colors.grey[300],
        },
        "&.whiteText": {
          color: colors.white,
        },
        color: colors.grey[700],
      },
      colorPrimary: {
        color: colors.primary.main,
      },
      colorSecondary: {
        color: colors.secondary.main,
      },
      colorTextSecondary: {
        color: colors.white,
      },
    },
    MuiGrid: {
      root: {
        "&.swyft-border-bottom": {
          borderBottom: "1px solid #e5e7eb",
        },
        "&.swyft-no-data": {
          position: "absolute",
          top: "30%",
          left: "50%",
          transform: "translate(-50%,-50%)",
        },
      },
    },
    MuiPaper: {
      root: {
        color: colors.grey[700],
        '& .MuiAutocomplete-option[aria-selected="true"]': {
          backgroundColor: colors.primary.light,
          color: colors.white,
        },
        "& .MuiAutocomplete-option": {
          "& .MuiTypography-root": {
            color: "inherit",
          },
        },
        "&.MuiAlert-standardError": {
          backgroundColor: colors.error.light,
          color: colors.error.main,
        },
        "& .MuiPickersDay-day": {
          color: colors.grey[700],
          "&.MuiPickersDay-current": {
            backgroundColor: colors.primary.main,
            color: colors.white,
            "&.MuiPickersDay-daySelected": {
              color: colors.white,
              backgroundColor: colors.primary.main,
            },
          },
          "&.MuiPickersDay-daySelected": {
            backgroundColor: colors.white,
            border: `1px solid ${colors.primary.main}`,
          },
        },
      },
    },
    MuiSelect: {
      root: {
        background: colors.white,
        border: `1px solid ${colors.grey[100]}`,
        fontSize: "0.75rem",
      },
      select: {
        borderRadius: 4,
        paddingLeft: "1rem",
        paddingRight: "2.5rem!important",
        marginRight: "-0.35rem",
        "&:focus": {
          borderRadius: 4,
          backgroundColor: colors.white,
          border: `1px solid ${colors.primary.main}`,
        },
        "&.Mui-disabled": {
          backgroundColor: colors.grey[50],
        },
      },
      filled: {
        padding: "0.75rem",
        borderColor: colors.grey[100],
        "& > .MuiSelect-selectMenu:hover": {
          background: colors.grey[100],
        },
      },
      selectMenu: {
        height: "24px",
        "&.lead-select": {
          height: "auto",
          margin: 0,
        },
      },
    },
    MuiTableSortLabel: {
      root: {
        "& > .MuiTableSortLabel-icon": {
          opacity: 1,
        },
      },
      active: {
        "& > .MuiTableSortLabel-icon": {
          color: `${colors.primary.main}!important`,
        },
      },
    },
    MuiInputBase: {
      root: {
        "&.swyft-input": {
          fontSize: "0.875rem",
        },
        "&.swyft-page-input, & .swyft-page-input": {
          fontSize: "12px",
          width: "40px",
          "& > input": {
            background: colors.white,
            textAlign: "center",
            border: `1px solid ${colors.grey[100]}`,
            color: colors.grey[900],
            borderRadius: 3,
            "&:focus": {
              borderColor: colors.primary.main,
            },
          },
        },
        "&.search-box": {
          fontSize: "0.875rem",
        },
        "&.lead-input": {
          background: colors.white,
          borderRadius: 5,
        },
        "&.placeholder": {
          "& .MuiInputBase-input": {
            color: colors.grey[300],
          },
        },
      },
      input: {
        color: colors.grey[700],
        "&::placeholder": {
          color: colors.grey[300],
        },
      },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        borderColor: colors.grey[100],
        borderWidth: "1px!important",
      },
      root: {
        "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
          borderColor: colors.grey[100],
        },
      },
      inputAdornedEnd: {
        marginRight: 10,
      },
    },
    MuiButton: {
      root: {
        padding: "0.625rem 1rem",
        borderRadius: 30,
        textTransform: "capitalize",
        minHeight: 46,
        "&.MuiButton-outlined": {
          padding: "0.5rem 1rem",
          "&.whiteButton": {
            background: "none",
            color: colors.white,
            borderColor: colors.white,
          },
        },
      },
      contained: {
        "& > .MuiButton-label": {
          color: colors.white,
          fontSize: "16px",
          fontWeight: 300,
        },
      },
      containedPrimary: {
        "&.Mui-disabled": {
          color: colors.white,
        },
      },
      containedSecondary: {
        "&.Mui-disabled": {
          color: colors.white,
        },
      },
      outlined: {
        backgroundColor: colors.white,
        fontSize: "16px",
        fontWeight: 300,
      },
      outlinedPrimary: {
        borderColor: colors.primary.main,
        "&.Mui-disabled": {
          backgroundColor: colors.white,
        },
      },
      outlinedSecondary: {
        borderColor: colors.secondary.main,
        "&.Mui-disabled": {
          backgroundColor: colors.white,
        },
      },
    },
    MuiIconButton: {
      root: {
        "&.swyft-bordered-button": {
          border: `1px solid rgba(0, 0, 0, 0.54)`,
        },
        "&.smallIcon": {
          padding: 0,
          "&:hover": {
            backgroundColor: "unset",
          },
        },
      },
      colorPrimary: {
        "&.swyft-close-button": {
          backgroundColor: colors.primary.main,
          "& > .MuiIconButton-label": {
            color: colors.white,
          },
        },
        "&.swyft-bordered-button": {
          backgroundColor: colors.white,
          border: `1px solid ${colors.primary.main}`,
        },
      },
      sizeSmall: {
        width: 36,
        height: 36,
      },
    },
    MuiTextField: {
      root: {
        borderColor: colors.grey[100],
        color: colors.grey[700],
        fontSize: "1rem",
        paddingRight: "0.275rem",
        paddingLeft: "0.275rem",
        "& > .Mui-focused .MuiInputAdornment-root ": {
          color: colors.primary.main,
        },
        "& > .Mui-disabled": {
          backgroundColor: colors.grey[50],
        },
        "& .MuiInputAdornment-root": {
          color: colors.grey[700],
        },
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: "0.875rem",
        "&.Swyft-MenuItem": {
          margin: "5px 0",
          "&.Mui-selected": {
            background: hexToRgbA(colors.primary.main, 0.1),
            color: colors.primary.main,
            fontWeight: 600,
            "&:hover": {
              background: hexToRgbA(colors.primary.main, 0.1),
            },
          },
        },
        "&.Border-MenuItem": {
          border: `1px solid ${colors.grey[100]}`,
          borderRadius: 3,
        },
      },
    },
    MuiListItem: {
      root: {
        "&.Mui-selected, &.Mui-selected:hover": {
          backgroundColor: colors.primary.light,
          color: colors.white,
        },
        "&.selectPlaceholder": {
          display: "none",
        },
      },
    },
    MuiFormLabel: {
      root: {
        fontFamily: fontFamily,
        color: colors.grey.DEFAULT,
        fontSize: "0.875rem",
        margin: "1rem 0",
      },
    },
    MuiFormControl: {
      root: {
        "& .Mui-error": {
          "& .MuiSelect-root": {
            borderColor: colors.error.main,
          },
        },
      },
    },
    MuiSvgIcon: {
      root: {
        "&.extra-large": {
          fontSize: "6rem!important",
        },
        "&.success-icon": {
          color: colors.success.main,
        },
        "&.warning-icon": {
          color: colors.warning.main,
        },
      },
    },
    MuiChip: {
      root: {
        color: colors.white,
        backgroundColor: colors.primary.light,
      },
      deleteIcon: {
        color: colors.white,
      },
    },
    MuiSwitch: {
      root: {
        width: 42,
        height: 24,
        padding: 0,
        display: "flex",
        borderRadius: 14,
        margin: "0 12px",
      },
      switchBase: {
        padding: 0,
        transform: "translate(5px, 5px)",
        "&$checked": {
          transform: "translate(23px, 5px)",
          color: colors.white,
          "& + $track": {
            opacity: 1,
            backgroundColor: colors.primary.light,
          },
        },
      },
      colorPrimary: {
        "&$checked": {
          "& + $track": {
            backgroundColor: colors.primary.light,
          },
        },
      },
      thumb: {
        width: 14,
        height: 14,
        boxShadow: `0px 1px 4px ${colors.grey[100]}`,
        backgroundColor: colors.white,
      },
      track: {
        borderRadius: 16 / 2,
        opacity: 1,
      },
    },
    MuiCheckbox: {
      root: {
        color: colors.grey[100],
        backgroundColor: "unset",
        "&:hover": {
          backgroundColor: "unset",
        },
      },
      colorPrimary: {
        "&:hover": {
          color: colors.primary.main,
          "&.success": {
            color: colors.success.main,
          },
          "&.warning": {
            color: colors.warning.main,
          },
          "&.error": {
            color: colors.error.main,
          },
          "&.info": {
            color: colors.info.main,
          },
        },
        "&$checked": {
          "&:hover": {
            backgroundColor: "unset",
          },
          "&$disabled": {
            color: colors.primary.light,
            "&.success": {
              color: colors.success.light,
            },
            "&.warning": {
              color: colors.warning.light,
            },
            "&.error": {
              color: colors.error.light,
            },
            "&.info": {
              color: colors.info.light,
            },
            "& .MuiSvgIcon-root": {
              backgroundColor: "unset",
              width: "1em",
              height: "1em",
              borderRadius: 0,
            },
          },
          "&.success": {
            color: colors.success.main,
          },
          "&.warning": {
            color: colors.warning.main,
          },
          "&.error": {
            color: colors.error.main,
          },
          "&.info": {
            color: colors.info.main,
          },
        },
        "&$disabled": {
          color: colors.grey[100],
          "& .MuiSvgIcon-root": {
            backgroundColor: colors.grey[100],
            width: 18,
            height: 18,
            borderRadius: 3,
          },
        },
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: colors.grey[50],
        "&.dash-divider": {
          borderBottom: `1px dashed ${colors.grey[100]}`,
          background: "unset",
        },
      },
    },
    MuiTab: {
      root: {
        "&.custom-tab": {
          color: colors.secondary.main,
          fontSize: "0.75rem",
          "&$selected": {
            backgroundColor: colors.white,
          },
        },
      },
    },
  },
};

export const BaseMuiTheme = createTheme(MuiTheme);
