import { Routes } from "@models/routes";
import { lazy } from "react";

const ViewProductPage = lazy(() => import("@pages/view-product/index").then((p) => ({ default: p.ViewProduct })));

export const ViewProductRoutes: Routes = [
  {
    path: "/view-product",
    component: ViewProductPage,
  },
];
