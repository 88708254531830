import { I18nProvider } from "@lingui/react";
import { i18n } from "@lingui/core";
import React, { useEffect } from "react";
import { defaultLocale, dynamicActivate } from "./i18n";
import { ThemeProvider } from "@material-ui/core";
import { BaseMuiTheme } from "@theme";
import { AppRoutes } from "./routers";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { AppProvider } from "./contexts/Context";
import { HelmetComponent } from "@config/HelmetComponent";
import { HelmetProvider } from "react-helmet-async";
import { PrismicProvider } from "@prismicio/react";
import { client } from "./prismic";
import { Link } from "react-router-dom";
import { gtm } from "@config/gtm";

const gtmId = "GTM-5ND2P9W";

function App() {
  React.useEffect(() => {
    // With this method we dynamically load the catalogs
    dynamicActivate(defaultLocale);
  }, []);

  useEffect(() => {
    gtm.initialize({
      containerId: gtmId,
    });
  }, []);

  return (
    <PrismicProvider client={client} internalLinkComponent={({ href, ...props }) => <Link to={href} {...props} />}>
      <HelmetProvider>
        <ThemeProvider theme={BaseMuiTheme}>
          <I18nProvider i18n={i18n}>
            <AppProvider>
              <>
                <HelmetComponent />
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <AppRoutes />
                </MuiPickersUtilsProvider>
              </>
            </AppProvider>
          </I18nProvider>
        </ThemeProvider>
      </HelmetProvider>
    </PrismicProvider>
  );
}

export default App;
