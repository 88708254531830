import { Routes } from "@models/routes";
import { lazy } from "react";

const Blog = lazy(() => import("@pages/blog/index").then((p) => ({ default: p.Blog })));

const BlogDetail = lazy(() => import("@pages/blog/BlogDetail").then((p) => ({ default: p.BlogDetail })));

const IncreaseBerPage = lazy(() =>
  import("@pages/blog/increase-ber").then((p) => ({
    default: p.IncreaseBerPage,
  }))
);

export const BlogRouters: Routes = [
  {
    path: "/blog",
    component: Blog,
    children: [
      {
        path: "increase-ber",
        component: IncreaseBerPage,
      },
      {
        path: ":id",
        component: BlogDetail,
      },
    ],
  },
];
