import { useAppContext } from "@contexts/Context";
import { Box, Button, Typography } from "@material-ui/core";
import { colors } from "@theme";
import React from "react";
import { useNavigate } from "react-router-dom";

export const PageNotFound = () => {
  const navigate = useNavigate();
  const { handleChangeTitleAndTag } = useAppContext();

  React.useEffect(() => {
    handleChangeTitleAndTag({
      title: "404",
      meta: "Page not found",
    });
  }, []);

  return (
    <Box bgcolor={colors.secondary.main} minWidth="100vw" minHeight="100vh" display="flex" textAlign="center">
      <Box
        className="box-container"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        my="auto" 
        mx="auto"
        maxWidth="80%"
        py={5}
      >
        <Box>
          <Typography className="whiteText" variant="h1">
            Oops!
          </Typography>
        </Box>
        <Box mt={4}>
          <Typography className="whiteText font-extralight" variant="subtitle2">
          We can&apos;t find the page that you are looking for. 
          </Typography>
        </Box>
        <Box width={610} maxWidth="100%" mt={{ xs: 5, md: 3 }}>
          <img loading="lazy" src="/assets/images/404/404.webp" />
        </Box>
        <Box mt={1}>
          <Button variant="contained" color="primary" onClick={() => navigate("/")}>
            <Typography className="whiteText font-extralight">Go back home</Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
