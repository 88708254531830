import { estimateLink } from "@constants";
import { Box, Button } from "@material-ui/core";
import { getEstimateLink } from "@utils/getEstimateLink";
import { handleEstimate } from "@utils/handleEstimate";
import { useNavigate } from "react-router-dom";

export const EstimateButton = ({
  color = "primary",
  mx = "auto",
  width = 186,
  url,
}: {
  color?: "primary" | "secondary";
  mx?: string | number;
  width?: string | number;
  url?: string;
}) => {
  const link = getEstimateLink();
  const navigate = useNavigate();

  // Determine if the page is a contact form page
  const isContactForm = link === estimateLink.services.contact_form;
  const isCommercialContactForm = link === estimateLink.services.commercial_contact_form;
  

  return (
    <Box height={46} width={width} mx={mx}>
      <Button
        variant="contained"
        color={color}
        fullWidth
        className="h-full"
        onClick={() => handleEstimate({ navigate, link: url || link })}
      >
        {isContactForm || isCommercialContactForm ? "Contact Us" : "Get Estimate"}
      </Button>
    </Box>
  );
};
