import { estimateLink } from "@constants";
import { useLocation } from "react-router-dom";

const boilerPathName = ["services/boiler", "boiler-replacement"];
const solarPathName = ["services/solar", "solar-panels"];
const contactPathName = ["services/contact_form", "schools-solar-panels","agricultural-solar-panels-landing"];
const commercialcontactPathName = ["services/commercial_contact_form", "commercial-solar-panels"];

export const getEstimateLink = () => {
  const { pathname } = useLocation();
  const isBoiler = !!boilerPathName.find((item) => pathname.includes(item));
  const isSolar = !!solarPathName.find((item) => pathname.includes(item) && !pathname.includes("schools-") && !pathname.includes("-landing") && !pathname.includes("commercial-solar-panels"));
  const isContact = !!contactPathName.find((item) => pathname.includes(item));
  const isCommercialContact = !!commercialcontactPathName.find((item) => pathname.includes(item));

  return isBoiler
    ? estimateLink.services.boiler
    : isSolar
    ? estimateLink.services.solar
    : isContact
    ? estimateLink.services.contact_form 
    : isCommercialContact
    ? estimateLink.services.commercial_contact_form 
   
    : estimateLink.services["default"];
};

