import { Routes } from "@models/routes";
import { lazy } from "react";

const SolarLocation = lazy(() =>
  import("@pages/location/SolarLocation").then((p) => ({
    default: p.SolarLocation,
  }))
);

const BoilerLocation = lazy(() =>
  import("@pages/location/BoilerLocation").then((p) => ({
    default: p.BoilerLocation,
  }))
);

export const LocationRoutes: Routes = [
  {
    path: "/:type-panels-:location",
    component: SolarLocation,
  },
  {
    path: "/:type-replacement-:location",
    component: BoilerLocation,
  },
];
