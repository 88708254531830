import { BottomNavigation, Box, CssBaseline, Fab, makeStyles, useScrollTrigger, Zoom } from "@material-ui/core";
import { KeyboardArrowUp } from "@material-ui/icons";
import { Layout, LayoutProps } from "@models/layout";
import { EstimateButton } from "@pages/homepage/components/EstimateButton";
import { useBreakpoints } from "@utils/breakpoints";
import React, { ReactElement } from "react";
import { useLocation } from "react-router-dom";
import { MainFooter } from "./MainFooter";
import { MainNavMenu } from "./MainNavMenu";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 100,
    [theme.breakpoints.down("sm")]: {
      bottom: 77,
    },
  },
  customBottomNavigation: {
    alignItems: "center",
    height: 69,
  },
}));

export function ScrollTop(props: { children: JSX.Element }) {
  const { children } = props;
  const classes = useStyles();
  const trigger = useScrollTrigger({
    target: window ? window : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event: React.MouseEvent) => {
    const anchor = ((event.target as HTMLDivElement).ownerDocument || document).querySelector("#back-to-top-anchor");
    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  React.useEffect(() => {
    window.history.scrollRestoration = "manual";
  }, []);

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" className={classes.root}>
        {children}
      </div>
    </Zoom>
  );
}

export function EstimateStickButton(props: { children: JSX.Element }) {
  const { children } = props;
  const { pathname } = useLocation();

  const [hasScroll, setHasScroll] = React.useState(false);

  React.useEffect(() => {
    const checkUserScroll = () => {
      const scrolled = document.documentElement.scrollTop;
      if (scrolled > 0 && !hasScroll) {
        setHasScroll(true);
      }
    };

    window.addEventListener("scroll", checkUserScroll);

    return () => window.removeEventListener("scroll", checkUserScroll);
  });

  React.useEffect(() => {
    if (pathname && hasScroll) {
      setHasScroll(false);
    }
  }, [pathname]);

  return <Zoom in={hasScroll}>{children}</Zoom>;
}

export const MainLayout: Layout = ({ children }: LayoutProps): ReactElement => {
  const { pathname } = useLocation();
  const { isLessThanSm } = useBreakpoints();
  const classes = useStyles();

  return (
    <Box className="flex flex-col min-h-full w-full overflow-y-auto overflow-x-hidden justify-center items-center mx-auto">
      <CssBaseline />
      <MainNavMenu />
      <Box flexGrow={1} className="flex m-auto w-full justify-center xs:mb-16 bg-white" minHeight="82vh">
        <Box width="100%" height="100%">
          {children}
        </Box>
      </Box>
      <MainFooter />
      {isLessThanSm && <Box mb={8.625} />}
      <ScrollTop>
        <Fab color="primary" size="small" aria-label="scroll back to top">
          <KeyboardArrowUp />
        </Fab>
      </ScrollTop>
      {isLessThanSm && (
        <EstimateStickButton>
          <Box
            position="fixed"
            bottom={0}
            right={0}
            left={0}
            zIndex={1200}
            boxShadow="0 -1px 4px 0 rgba(173, 173, 173, 0.5)"
          >
            <BottomNavigation className={classes.customBottomNavigation}>
              <EstimateButton
                width="80%"
                url={
                  pathname.includes("solar-commercial-finance")
                    ? "https://swyft.swyftenergy.ie/questionnaire/e63588ac-818b-4a9e-7d7e-08d96098105b"
                    : undefined
                }
              />
            </BottomNavigation>
          </Box>
        </EstimateStickButton>
      )}
    </Box>
  );
};
