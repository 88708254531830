import { SafeAny } from "@models/common";
import { ChangeTitleAndTag, EAction, InitialState, TitleAndTagPayload } from "@models/context";
import React, { createContext, Dispatch, useCallback, useContext, useMemo, useReducer } from "react";

export interface AppProviderProps {
  children: JSX.Element;
}

export type ActionState = ChangeTitleAndTag;

const initialState: InitialState = {
  title: "",
  meta: "",
};

export const MainReducer = (state: InitialState, action: SafeAny) => {
  switch (action.type) {
    case EAction.CHANGE_TITLE_AND_TAG:
      return {
        ...state,
        title: action.payload.title,
        meta: action.payload.meta,
      };
    default:
      return state;
  }
};

export const AppContext = createContext<{
  state: InitialState;
  dispatch: Dispatch<ActionState>;
  handleChangeTitleAndTag: (payload: TitleAndTagPayload) => void;
}>({
  state: initialState,
  dispatch: () => null,
  handleChangeTitleAndTag: () => null,
});

export const useAppContext = () => useContext(AppContext);

export const AppProvider = ({ children }: AppProviderProps) => {
  const [state, dispatch] = useReducer(MainReducer, initialState);

  const handleChangeTitleAndTag = useCallback(
    (payload: TitleAndTagPayload) => dispatch({ type: EAction.CHANGE_TITLE_AND_TAG, payload }),
    [dispatch]
  );

  const value = useMemo(
    () => ({
      state,
      dispatch,
      handleChangeTitleAndTag,
    }),
    [state, dispatch, handleChangeTitleAndTag]
  );

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};
